/* App.css */

body {
  background-color: #121212; /* Dark background */
  color: #e0e0e0; /* Light gray text for contrast */
  font-family: 'Arial', sans-serif; /* Clean font */
}

.container {
  padding: 20px;
}

.card {
  background-color: #1e1e1e; /* Slightly lighter dark background for cards */
  align-items: center;
  border: none; /* Remove default border */
  border-radius: 12px; /* Rounded edges */
  transition: transform 0.2s; /* Smooth scaling on hover */
  max-height: 85vh;
  margin: auto; /* Center the card */
  padding: 1px;
}


  /* transform: scale(1.02); Slightly scale up on hover */


.card-title {
  font-size: 1.5rem; /* Adjust title size */
  margin-bottom: 10px; /* Spacing below title */
  padding: 15px;
}

.card-img-top {
  border-radius: 8px; /* Rounded top edges for images */
  display: block; /* Make it a block element */
  margin: 0 auto; /* Center the image */
  width: auto; /* Maintain aspect ratio */
  height: auto; /* Maintain aspect ratio */
}

.question-media {
  border-radius: 8px; /* Rounded top edges for images */
  display: block; /* Make it a block element */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin: 15px auto; /* Space around the media */
  max-height: 20rem; /* Ensure it doesn't overflow the card */
  max-width: 100%; /* Ensure it doesn't overflow the card */
  width: auto; /* Maintain aspect ratio */
  height: auto; /* Maintain aspect ratio */
}


.list-group-item {
  background-color: #2a2a2a; /* Background for answer options */
  font-size: 16px;
  border-radius: 8px; /* Rounded edges */
  padding: 5px; /* Padding around answers */
  margin: 3px; /* Space between answers */
  transition: background-color 0.2s; /* Transition for hover effect */
}

.list-group-item:hover {
  background-color: #3a3a3a; /* Slightly lighter on hover */
}

.loby-list-item {
  background-color: #2a2a2a; /* Background for answer options */
  border-radius: 0 !important; /* Rounded edges */
  border-top-color: #e0e0e0;
  border-bottom-color: #e0e0e0;
  padding: 2px; /* Padding around answers */
  margin: 0px; /* Space between answers */
  transition: background-color 0.2s; /* Transition for hover effect */
}

button {
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}
